import React from "react";
import "./Tools.css"
import Card from "./Card";
import Fr1 from "../src/images/Fr1.jpg"
import Fr2 from "../src/images/Fr2.png"
import Fr3 from "../src/images/Fr3.jpg"
import Fr4 from "../src/images/Fr4.jpg"
import C2 from "../src/images/C2.jpg"
import C3 from "../src/images/C3.jpg"
import C5 from "../src/images/C5.png"
import C6 from "../src/images/C6.png"
import T from "../src/images/T.png"
import O from "../src/images/O.png"
import M from "../src/images/M.png"
import E from "../src/images/E.png"
import R1 from "../src/images/R1.jpg"
import R2 from "../src/images/R2.png"
import R3 from "../src/images/R3.jpg"
import R4 from "../src/images/R4.png"
import P1 from "../src/images/P1.jpg"
import P2 from "../src/images/P2.png"
import P3 from "../src/images/P3.jpg"
import P4 from "../src/images/P4.png"
import Q1 from "../src/images/Q1.jpeg"
import Q2 from "../src/images/Q2.png"
import Q3 from "../src/images/Q3.png"
import Q4 from "../src/images/Q4.png"
import B1 from "../src/images/Bing-3.jpeg"
import B2 from "../src/images/Bing-4.jpeg"
import B3 from "../src/images/Bing-6.jpeg"
import B4 from "../src/images/Bing-7.jpeg"
import D1 from "../src/images/D1.jpeg"
import D2 from "../src/images/D2.png"
import D3 from "../src/images/D3.jpg"
import D4 from "../src/images/D4.jpg"
import K1 from "../src/images/K1.png"
import K2 from "../src/images/K2.png"
import K3 from "../src/images/K3.png"
import K4 from "../src/images/K4.png"
import L1 from "../src/images/Lai-5.jpg"
import L2 from "../src/images/Lai-8.jpg"
import L3 from "../src/images/Lai-1.jpg"
import L4 from "../src/images/Lai-4.jpg"

function Tools(){

    return(
        <div>
            <Card 
            tname="10. Framer AI "
            text1="At number 10, we have Framer AI, functioning as a smart assistant 🧐 for crafting websites. It utilizes AI to streamline and infuse creativity into the website creation process. Framer AI empowers users to effortlessly generate new pages, tailor translations, and enhance text with remarkable ease. 🤖"

            text2="The standout feature? 💪 Its specialized AI translation tool is specifically geared towards website translation, significantly enhancing user experience 🖥️. Framer AI simplifies website creation, especially for individuals not well-versed in design or coding. It accelerates the entire process, enabling swift experimentation with fresh designs 🎨."
            
            text3="🧑 Speaking from personal experience, I'd suggest the other sim, which, in my opinion, surpasses Framer. However, Framer offers an elevated level of customization.🔍 Notably, it enables the conversion of Figma prototypes into websites, allowing users to explore innovative possibilities in website development.
            "
            link="https://www.youtube.com/embed/iS03Coj96Rk"

            vid_title="Framer Tutorial: Generate A Live Website With AI"

            src1={Fr1}
            src2={Fr2}
            src3={Fr3}
            src4={Fr4}

            />


            <Card 
            tname="9. Canva AI"
            text1="At number 9, there's Canva AI! It's part of the Canva platform you're likely familiar with. Canva, that fantastic online image editing tool packed with templates and amazing customization features, now includes Canva AI for image generation.🖼️"

            text2="This AI offers some nifty features 📕. Imagine a Background Remover that wipes out backgrounds with just one click! Then, there's the Magic Studio, AI-powered to assist in design with tools like Magic Switch, Magic Write, Magic Editor, Magic Resize, and Magic Animator. On top of that, it includes impressive AI tools such as the Art Generator and Image Generator ✨."
            
            text3="But honestly, compared to some other options out there, Canva AI might not be the absolute best. It's definitely useful, though! And hey, when you log in, you start with 50 credits 🟡. Each set of image generations uses 1 credit.
            Definitely worth exploring! Take a look at these sample creations to get an idea.            
            "
            link="https://www.youtube.com/embed/pyI820GhtJo"

            vid_title="Canva AI: 5 New Features That Will BLOW YOUR MIND!"

            
            src1={C2}
            src2={C3}
            src3={C5}
            src4={C6}
            
            />


            <Card 
            tname="8. Tome AI"
            text1="Let's talk about number 8 – Tome AI. It's your go-to tool 🔨 when you're short on time for a presentation. This magic tool✨ quickly turns text into these eye-catching presentations. They're so versatile – perfect for fundraising, sales talks, team meetings, or design reviews. Plus, it's super handy for collaborating on projects too!
            "

            text2="Oh, and when you sign in, you get some free credits 🎁 to kick things off. These credits are used to generate the content. If you happen to run out of credits, it'll prompt you to purchase more. By the way, it generates those impressive images 🎨 in the slides using OpenAI's DALL·E 2.
            "
            
            text3="Though, gotta say, while the AI does a solid job, I still prefer crafting 📏 my own slides. But remember, when you're rushing and need a presentation pronto, this tool's an absolute lifesaver!"


            text4="Oh, and don't forget, you can access a sample presentation on the topic of solving the problem of traffic congestion 🚓."

            href = "https://tome.app/vaibhav-ed3/solving-traffic-congestion-in-metropolitan-cities-clqgmhh6g0kwho75ym5gz10iu"

            text5=" Click Here "
            link="https://www.youtube.com/embed/QoctedRQz3c"

            vid_title="How To Use Tome AI Tool To Create Stunning Presentations Instantly (Step By Step Tutorial)"

            
            src1={T}
            src2={O}
            src3={M}
            src4={E}
            />


            <Card 
            tname="7. Replit AI"
            text1="Replit AI, holding the number 7 spot, serves as an AI toolbox within the Replit platform, dedicated to enhancing your 📊 development journey. It encompasses tools like Complete Code, Generate Code, Edit Code, Explain Code, and Chat.
            "

            text2="Let's dive deeper: Complete Code offers code suggestions by analyzing your ongoing file 📂, taking into account comments and prompt engineering for superior results. Supporting 16 programming languages, with a focus on JavaScript and Python, it's freely available to all users. For more advanced features, the Replit Core membership is the key.
            "
            
            text3="
            But that's not all! Replit AI also houses a chat-based AI assistant 🤖 and a debugger, furnishing step-by-step guidance and hints for resolving code-related challenges 🏹.
            "


            text4="This AI isn't a mere novelty; it's tailored to streamline coding 🚆, making it more efficient and simplifying the debugging process. Drawing from personal experience, I whipped up a basic snake game in mere seconds using this AI—something that took me days . It's undeniably a valuable asset for programmers! 🧑

            Additionally, Replit has forged a partnership with Google Cloud in this endeavor.
            "


            link="https://youtube.com/embed/79GKxffzYIE"

            vid_title="Replit: How to generate code faster with gen AI"

            
            src1={R1}
            src2={R2}
            src3={R3}
            src4={R4}
            />


            <Card 
            tname="6. Perplexity AI"
            text1="At the 6th position, we've got Perplexity AI, the sibling 👦 of ChatGPT, with some significant distinctions. This tool resembles ChatGPT but with notable differences. One standout feature is its Chrome extension, a remarkably useful addition. With just a click, you can access the tool 🔧 rather than searching for it online. The extension can summarize the current webpage or a PDF you've opened within seconds—a handy capability.
            "

            text2="Similar to Bing AI, Perplexity AI provides source links for its responses and boasts notably faster response times. Unlike ChatGPT, Perplexity AI leans towards being information-oriented 📺 rather than conversation-oriented 🧑👨. It not only suggests follow-up questions but also fetches relevant images from the web related to your query.
            "
            
            text3="
            Engaging with Perplexity AI feels more like interacting with a database 📖 of information rather than having a conversation with a human. However, its accuracy in providing facts and information is impressive. In contrast, ChatGPT offers a more conversational experience, making users feel like they're chatting with another person, which many find comfortable.
            "


            link="https://youtube.com/embed/k05spcxoNgs"

            vid_title="All Features you didn't know. Get the most out of Perplexity AI! [How to use Perplexity Al for Pros]"

            
            src1={P1}
            src2={P2}
            src3={P3}
            src4={P4}
            />

            <Card 

            tname="5. QuestGen"
            text1="At number 5, we've got an incredibly fascinating 🎆 tool that's a real game-changer for teachers and an absolute boon for students: Questgen. It's an AI-powered tool where you input text and get various types of questions 📄 generated from it. What's cool is that while generating questions, you can choose from a range of options like multiple-choice questions, true or false, fill in the blanks, FAQ-style questions, and even higher-order thinking questions. Plus, you can tweak the difficulty level 📈 and number of questions you want.
            "

            text2="The surprising part? 🤩 This AI tool is completely free, and there's no credit system involved. There's a lot more to explore on their website, with numerous additional features worth checking out 📱. However, for free users, there are some limitations—you can input a maximum of 5000 words, while paid users 💸💸 get the luxury of entering up to 80,000 words. Additionally, paid users can upload documents in formats like PDF or through URLs.
            "
            
            text3="Feel free to explore more about it on their website to uncover its full range of features and possibilities!"


            link="https://youtube.com/embed/akRWIQ4R5Zs"

            vid_title="AI powered question generator : Questgen.ai"

            
            src1={Q1}
            src2={Q2}
            src3={Q3}
            src4={Q4}
            />



            <Card 
            tname="4. KickResume"
            text1="At the 5th spot, we have Kickresume. It's like having a reliable buddy 🧑 to craft winning resumes. Its clever AI tailors your resume for each job you're aiming for. You get various styles 🎨 to choose from, and it's got your back—it catches any spelling or grammar mistakes.
            "

            text2="But wait, there's more! It doesn't just stop there. Kickresume suggests keywords to catch employers' 🤵 attention. It guides you with examples and tips for a compelling summary 📃 and even whips up a cover letter that matches your resume style. It's a game-changer for anyone job hunting! 👨‍💼."
            
            text3="Oh, and here's the cherry on top: Kickresume can import info from your LinkedIn profile and offers a slick resume analysis  📊 feature. It's packed with handy extras!
            
            "
            
            text4="And hey, to give you an idea, here's a sample of what it can generate:"


            link="https://youtube.com/embed/akRWIQ4R5Zs"

            vid_title="AI powered question generator : Questgen.ai"

            href = "https://www.kickresume.com/en/help-center/summary-resume-sample/"

            text5=" Click Here "
            
            src1={K1}
            src2={K2}
            src3={K3}
            src4={K4}
            />

            <Card 
            tname="3. Bing CoPilot"
            text1="At number 10, we have Microsoft's Bing CoPilot 👨🏼‍🤝‍👨🏼, and it's intriguing! It shares resemblances with ChatGPT, but it brings some distinctive elements to the table. One standout feature is its capability to provide source links 🌏, which is incredibly handy when you want to verify information ✅. I was pleasantly surprised to learn that it can also summarize PDFs, adding another layer of usefulness to its capabilities..
            "

            text2="The image capabilities are amazing—users can upload images and have Bing CoPilot describe or even generate images for them, all powered by OpenAI's DALL·E 3. Pretty impressive, wouldn't you say?"
            
            text3="On a personal note, the user interface felt really intuitive and user-friendly 🌟, which made the whole experience enjoyable. The accuracy of its responses was quite impressive 🤩 too. However, one downside I noticed was that it tended to take a bit longer to respond compared to what I'm used to."
            
            text4="Here are some sample image generations I got."


            link="https://youtube.com/embed/QaMQgu5REOY"

            vid_title="Ultimate Guide to Bing AI with DallE 3 Image Generator"
            
            src1={B1}
            src2={B2}
            src3={B3}
            src4={B4}
            />

            <Card 
            tname="2. Durable.Co"
            text1="Sitting at number 2, we have Durable.co, a seriously awesome tool 🪓!  Have you heard about AI potentially taking over jobs? Well, this might just revolutionize how we approach front-end development 💻. Believe it or not, this AI whizzes through turning plain text into a complete website in less than 20 seconds!
            "

            text2="I played around with it using a make-believe business called 'Maximus Cakes' 🍰 and wow, the website it produced was way beyond good-looking. It was incredibly organized and easy to use, with this really cool user 👦 dashboard."
            
            text3="And guess what? If you opt for the premium plan, you get some sweet extras like CRM, Auto Reply, and a custom domain. It's kind of mind-boggling to think that small businesses 🏠 might consider shelling out more for developers when this AI does such an impressive job!
            "
            
            text4="Check out the generated sample, It's seriously mind-blowing stuff!"


            link="https://youtube.com/embed/D-z-uAvAqTk"

            vid_title="Durable AI Website Builder Tutorial | AI Tool Kit, CRM, Invoicing & More for Small Biz"

            href = "https://maximuscakes.mydurable.com/"

            text5=" Click Here "
            
            src1={D1}
            src2={D2}
            src3={D3}
            src4={D4}
            />


            <Card 
            tname="1. Leonardo AI"
            text1="Alright, let's dive into Number 1 🥇 – Leonardo AI. This tool's all about creating images , using this cool tech called Stable Diffusion Technology. What's neat is, with Leonardo AI, you can actually train your own custom models, giving you loads of control and options.
            "

            text2="So, here's the thing about Leonardo compared ⚔️ to Midjourney. Leonardo AI offers extensive customization for various industries dealing with abundant data, albeit requiring specific instructions. It's presently free. Midjourney emphasizes user-friendliness, vibrant designs ✨, and detailed art, but requires a paid subscription for access.
            "
            
            text3="A really cool thing about Leonardo AI is that users receive 150 free credits 🍪 every day, which they can use to generate images.Let me demonstrate the significance of providing specific details when using Leonardo AI. A basic and vague initial prompt generates different images compared to a much more detailed one. Take a look at the contrast in the generated images."


            link="https://youtube.com/embed/36Puoapeco4"

            vid_title="Leonardo Ai - Complete Tutorial"
            
            src1={L1}
            src2={L2}
            src3={L3}
            src4={L4}
            />

        </div>
    );
}

export default Tools;
