import React from "react";
import "./ai_int.css"
import chatgpt from "./images/chatgpt.png"
import bing from "./images/bing.png"
import xai from "./images/xai.png"
import midj from "./images/midjourney.png"

function ai_interactive(){
    return(
        <div className="pic_div">
            <img src={chatgpt} alt="GG" className="ai_img ai gpt" />
            <img src={xai} alt="GG" className="ai_img ai xai " />
            <img src={bing} alt="GG" className="ai_img ai bing" />
            <img src={midj} alt="GG" className="ai_img ai midj" />
        </div>
    );

}

export default ai_interactive;