import React from "react";
import "./Content.css"
import AI from "./ai_int";


function content(){
    return(
        <div className="content-main">
            
            <h1 className="font">Introduction: Why AI ?</h1>

            <p className="main-body">
                Hey guys! 👋 How's it going? Let's jump into the world of AI together, shall we? I promise it's not just some mysterious tech talk or something out of a sci-fi flick. In simple words, AI or Artificial Intelligence, is like that ultra-smart buddy working behind the scenes in our digital world. <br/><br/>

                <strong>Visualize this –</strong> AI's the brain behind those addictive Netflix recommendations or the lifesaver typo fixes. It's everywhere, doing incredible things, and honestly, it's mind-blowing and this is only going to get better! 🤯 <br/><br/>

                Imagine machines learning, adapting, and sometimes even outsmarting us humans. That's the magic of AI! It's like carrying around a bunch of mini-geniuses in your pocket. 🧠✨ <br/><br/>

                <strong>But here's the thing -</strong> People tend to get stuck with ChatGPT and often miss out on exploring beyond. I totally get it! ChatGPT is fantastic, no doubt, but trust me, there's an entire universe of fantastic AI tools waiting to be discovered. <br/><br/>

                So, I've been in your shoes, testing a bunch of them. It's been quite an adventure! 🌿 After a ton of trial and error, I've combed through the galaxy of AI tools and handpicked the absolute best ones – the ones that'll make your life as a student/working professional a whole lot easier. <br/><br/>

                <strong>Here's the plan -</strong> For each AI tool, I'll give you a quick intro, show you a sample generation <strong> (visible only on laptop screen) </strong>it produced for me (because it's mind-blowing), and even share the best YouTube video I could find to learn more about it. <br/><br/>

                So, let's gear up! I'm super excited to share these amazing AI tools with you. They're not just your Plain-Vanilla tools; they're the ones that can seriously revolutionize the way you work. Let's embark on this AI tool journey together – no complex tech talk, just a friendly chat between us. <br/><br/>
            </p>

            <AI className="AI"/>

        </div>

    );
}

export default content;