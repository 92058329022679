import React from 'react';
import Navbar from './Navbar';
import Content from './Content';
import Tools from './Tools';
import Footer from './Footer';


function App() {
  return (
    <div>
      <Navbar />
      <Content />
      <Tools />
      <Footer />
    </div>
  );
}

export default App;
