import React from 'react';
import { Container } from 'react-bootstrap';
import "./Footer.css"

const Footer = () => {
  return (
    <footer>
      <Container fluid>
        <p>This site is a work in progress. Stay tuned for updates!</p>
        <p>&copy; Vaibhav Isloor</p>
      </Container>
    </footer>
  );
};

export default Footer;
