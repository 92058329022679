import React from "react";
import "./Navbar.css"
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Navbar, Nav } from 'react-bootstrap'; // Import Navbar components from React Bootstrap
import { FaYoutube } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";





function Navbar_main()
{
    return(
            <Navbar bg="black" expand="lg" className="sticky-top">
            <div className="container-fluid "> {/* Apply container-fluid class */}
              <Navbar.Brand href="#home"><h1 className="vaibhav-main">Vaibhav Isloor</h1></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="https://www.youtube.com/@MaximusGamingGG"><FaYoutube size="3rem" className="icon wiggle yt "/></Nav.Link>
                  <Nav.Link href="https://www.youtube.com/@MaximusGamingGG"><FaXTwitter size="3rem" className="icon wiggle x"/></Nav.Link>
                  <Nav.Link href="https://www.linkedin.com/in/vaibhav-isloor-60a680253/"><FaLinkedin size="3rem" className="icon wiggle ln"/></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
    );

}

export default Navbar_main;