import React, { useEffect } from "react";
import "./Card.css"
import AOS from 'aos';

function Card(props){
    useEffect(() => {
        AOS.init(); 
      }, []);
    return(
        <div data-aos="fade-right" data-aos-offset="150" data-aos-easing="ease-in-out">
            <h1 className="title-text">{props.tname}</h1>
            <p className="tool_text">{props.text1}</p>
            <p className="tool_text">{props.text2}</p>
            <p className="tool_text">{props.text3}</p>
            <p className="tool_text">{props.text4}</p>
            <a className="tool_text a" href={props.href}> {props.text5} </a>
            <iframe className="yt_video" src={props.link} title={props.vid_titl} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="tools_div"> 
                    <img src={props.src1} alt="image1" className="std_img src1"/>
                    <img src={props.src2} alt="image2" className="std_img src2"/>
                    <img src={props.src3} alt="image3" className="std_img src3"/>
                    <img src={props.src4} alt="image4" className="std_img src4"/>
                </div> 
        </div>
    );
}

export default Card;

