import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import 'aos/dist/aos.css'; 
import AOS from 'aos';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

AOS.init(); 
